import React from "react"
import { Link } from "gatsby"
import Fade from "react-reveal/Fade"
import BlockCta from "src/components/Blocks/BlockCta"
import CrossfadeImage from "react-crossfade-image"
import { RichText } from "prismic-reactjs"
import Img from "gatsby-image"
import linkResolver from "src/utils/linkResolver"
import GatsbyLink from "src/utils/GatsbyLink"

const Configurations = data => {
  const typeDashed = data.type.replace(/\s+/g, "-").toLowerCase()

  return (
    <React.Fragment>
      <section>
        <div className="container text-center">
          <Fade distance="20px" bottom>
            <h2 className="font-display text-gold rfs:text-6xl max-w-lg m-auto capitalize">
              {data.type} Configurations
            </h2>
            <div className="w-12 mt-5 separator"></div>
            <div className="mt-5 max-w-xl m-auto">
              <p>
                { data.type !== 'garage door' ? (
                  'Our extensive choice of ' + data.type + ' configurations enables you to find a door to perfectly suit the needs of the room – and you don’t have to be limited to an existing structure. Hinged single and double doors are also available in full-height floor-to-ceiling options, while other configurations are available upon request.'
                ) : (

                  'Our offering of ' + data.type + ' configurations enable you to find a door that perfectly suits your needs. Carefully consider how you use the space in which the door will be installed, then choose from our two configurations - side sectional or overhead sectional.'

                ) }
              </p>
              <p className="font-bold text-sm mt-6">
                Doors shown are for representative example only. Door
                configurations are available in many door styles, woods and
                colours.
              </p>
            </div>
          </Fade>
          <div className="flex flex-col lg:flex-row flex-wrap mt-16 justify-center configurations">
            {data.edges.map((item, index) => {
              return (
                <Link
                  to={
                    item.node.data.link.url !== "/undefined"
                      ? `/${typeDashed}s/configurations/${item.node.data.link.url}/`
                      : "/"
                  }
                  key={index}
                  className="group relative w-full lg:w-4/12 p-2 text-left mb-0"
                  title={`${item.node.data.title.text}`}
                >
                  <Fade>
                    <div className="door-images w-full relative bg-black">
                      <CrossfadeImage
                        src={item.node.data.door_image.url}
                        alt={item.node.data.title.text}
                        containerClass={`absolute top-0 left-0 w-full flex justify-center items-end transition duration-500 ease-in-out group-hover:opacity-70 h-full`}
                      />
                      {item.node.data.featured_video ? (
                        <video
                          className="absolute top-0 left-0 opacity-0 group-hover:opacity-100 transition duration-1000 ease-in-out"
                          autoPlay
                          muted
                          loop
                          playsInline
                        >
                          <source
                            src={item.node.data.featured_video.url}
                            type="video/mp4"
                          />
                        </video>
                      ) : (
                        <img
                          src={item.node.data.featured_image.url}
                          alt={item.node.data.featured_image.alt}
                          className="absolute top-0 left-0 opacity-0 group-hover:opacity-100 transition duration-1000 ease-in-out"
                        />
                      )}
                    </div>
                  </Fade>
                  <Fade>
                    <h2 className="text-darkgrey font-bold text-xs tracking-widest uppercase my-4 group-hover:text-gold duration-500 ease-in-out">
                      {item.node.data.title.text}
                    </h2>
                  </Fade>
                </Link>
              )
            })}
          </div>
        </div>
      </section>
      <section className={`bg-offshite`}>
        <div className={`container`}>
          <div className={`flex flex-col space-y-12 lg:space-y-0 lg:flex-row`}>
            <div className="w-full lg:w-7/12">
              <div className="grid grid-cols-2 gap-4">
                <Fade>
                  <div>
                    {data.type === "internal door" && (
                      <img
                        src={`https://images.prismic.io/deuren/6680dbfb-b7f6-4f1f-835c-cd7310f803b5_Deuren-Configs-Top-Level-All-our-door-styles-are-available-in-many-configurations-1.jpg?auto=compress,format`}
                        srcSet={``}
                        alt={`All our door styles are available in many configurations`}
                      />
                    )}
                    {data.type === "front door" && (
                      <video className="w-full" autoPlay loop muted playsInline>
                        <source
                          src="https://d2jpqhjplyyyp0.cloudfront.net/Deuren-Front.mp4"
                          type="video/mp4"
                        />
                      </video>
                    )}
                    {data.type === "garage door" && (
                      <img
                        src="https://images.prismic.io/deuren/f49af094-0678-42f9-b6e3-96adb0aba2a4_All-our-door-styles-are-available-in-many-configurations-1.jpg?auto=compress,format"
                        alt="All our door styles are available in many configurations"
                      />
                    )}
                  </div>
                </Fade>
                <Fade>
                  <div>
                    {data.type === "internal door" && (
                      <video className="w-full" autoPlay muted loop playsInline>
                        <source
                          src={`https://d2jpqhjplyyyp0.cloudfront.net/Deuren-Configs.mp4`}
                          type="video/mp4"
                        />
                      </video>
                    )}
                    {data.type === "front door" && (
                      <img
                        src="https://images.prismic.io/deuren/cac3ac14-6ea9-4938-b0d0-7c7d6d33501b_All-our-door-styles-are-available-in-many-configurations-1.jpg?auto=compress,format"
                        alt="All our door styles are available in many configurations"
                      />
                    )}
                    {data.type === "garage door" && (
                      <img
                        src="https://images.prismic.io/deuren/c6287e06-91b4-4c91-a2bb-b3e83c077c38_All-our-door-styles-are-available-in-many-configurations-2.jpg?auto=compress,format"
                        alt="All our door styles are available in many configurations"
                      />
                    )}
                  </div>
                </Fade>
              </div>
            </div>
            <div className={`w-full lg:w-5/12 lg:pl-20`}>
              <div className="sticky top-32 text-center lg:text-left">
                <Fade distance="20px" bottom>
                  <h1 className="font-display text-gold rfs:text-6xl">
                    All our door styles are available in many configurations
                  </h1>
                  <div className="w-12 mt-5 separator"></div>
                  <div className="mt-5">
                    <p>
                      Deuren can craft doors to almost any configuration – from
                      discreetly sliding pocket doors to space-saving bi-fold
                      doors. Think about how you use the space in which the door
                      will be installed and opt for a configuration that
                      beautifully enhances that. Create statement pieces of the
                      finest quality to really bring your home to life.
                    </p>
                  </div>
                  <Link
                    className="btn mt-6"
                    to={`/${typeDashed}s/styles/`}
                    role="button"
                  >
                    See all {data.type} styles
                  </Link>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-offwhite">
        <div className="container text-center">
          <Fade>
            <h2 className="font-display text-gold rfs:text-5xl">Our Blog</h2>
            <div className="w-12 separator mt-5"></div>
            <p className="max-w-lg m-auto mt-6">
              Take a look at our industry insights, recommendations and
              favourite topics in the wonderful world of doors.
            </p>
          </Fade>
          <div className="mt-16 flex flex-row justify-center flex-wrap lg:space-y-0 space-y-12">
            {data.blogsData.edges.map((item, index) => {
              return (
                <div className="md:flex w-full lg:w-1/2 lg:pr-4 border-l border-gold group text-left">
                  <Fade>
                    <Link
                      to={linkResolver(item.node)}
                      key={index}
                      title={`${item.node.data.title.text}`}
                      className="group text-left block"
                    >
                      <Img
                        fluid={item.node.data.featured_image.fluid}
                        alt={item.node.data.title.text}
                        className="-ml-px transition duration-500 ease-in-out group-hover:opacity-70 max-w-full h-0 pb-[60%]"
                      />
                      <div className="p-6 pb-0">
                        <date className="text-darkgreysecond text-xs">
                          {item.node.data.post_date} -{" "}
                          {item.node.tags.join(", ")}
                        </date>
                        <h2 className="lg:h-8 text-darkgrey font-bold text-xs tracking-widest uppercase my-6 group-hover:text-gold duration-500 ease-in-out">
                          {item.node.data.title.text}
                        </h2>
                        <RichText
                          render={item.node.data.description.richText}
                          serializeHyperlink={GatsbyLink}
                        />
                      </div>
                    </Link>
                  </Fade>
                </div>
              )
            })}
          </div>
          <Link to="/our-blog/" className="btn mt-12">
            See our blog
          </Link>
        </div>
      </section>
      <BlockCta disabled={data.remove_default_footer_ctas} />
    </React.Fragment>
  )
}

export default Configurations

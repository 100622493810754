import React from "react"
import { graphql } from "gatsby"
import Seo from "src/utils/Seo"
import Breadcrumbs from "src/utils/Breadcrumbs"
import Configurations from "src/components/Product/Configurations"

const ArchiveFrontDoorConfigurations = ({
  data: { allPrismicConfigurations, allPrismicBlogPost },
}) => {
  const data = allPrismicConfigurations
  const blogsData = allPrismicBlogPost

  const crumbs = [
    { label: "Front Doors", href: "/front-doors" },
    {
      label: "Door Configurations",
      href: "/front-doors/configurations",
      active: true,
    },
  ]

  return (
    <main>
      <Seo title={"Front Door Configurations | Front Door Types"}
        description={
          "Our extensive choice of front door configurations enables you to find a door to perfectly suit the needs of the room. Find the perfect style for you with Deuren"
        } />
      <div className="py-5">
        <Breadcrumbs data={crumbs} theme="light" />
      </div>
      <Configurations {...data} blogsData={blogsData} type={"front door"} />
    </main>
  )
}

export default ArchiveFrontDoorConfigurations

export const frontDoorConfigQuery = graphql`
  query FrontDoorConfig {
    allPrismicConfigurations(
      filter: { data: { configuration_type: { eq: "Front Door" } } }
    ) {
      edges {
        node {
          data {
            configuration_type
            title {
              text
            }
            link {
              url: uid
            }
            featured_image {
              url
              alt
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
            door_image {
              url
              alt
            }
          }
        }
      }
    }
    allPrismicBlogPost(
      limit: 2
      filter: { tags: { eq: "Front Doors" } }
      sort: { order: DESC, fields: data___post_date }
    ) {
      edges {
        node {
          uid
          type
          tags
          data {
            post_date(formatString: "DD MMM YYYY")
            title {
              text
            }
            featured_image {
              url
              alt
              fluid(srcSetBreakpoints: [400, 800]) {
                srcSet
              }
            }
            description {
              richText
            }
          }
        }
      }
    }
  }
`
